import { render, staticRenderFns } from "./FileLoaderUrl.vue?vue&type=template&id=97565c62&scoped=true&"
import script from "./FileLoaderUrl.vue?vue&type=script&lang=js&"
export * from "./FileLoaderUrl.vue?vue&type=script&lang=js&"
import style0 from "./FileLoaderUrl.vue?vue&type=style&index=0&id=97565c62&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97565c62",
  null
  
)

export default component.exports